@-webkit-keyframes sk-fade-in {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@-moz-keyframes sk-fade-in {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@-ms-keyframes sk-fade-in {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

@keyframes sk-fade-in {
  0% {
      opacity: 0;
  }
  50% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}

.sk-fade-in {
  -webkit-animation: sk-fade-in 2s;
  -moz-animation: sk-fade-in 2s;
  -o-animation: sk-fade-in 2s;
  -ms-animation: sk-fade-in 2s;
}
